<template>
	<div class=''>
		<mzSwiper :image='bannerImg'></mzSwiper>

		<div class="mt20 w-max bg-f pt16 color-f pb30">
			<div class="fs16 mt4 text-center mr50 ml50 color0">{{name}}</div>
			<!-- 轮播图 -->
			<van-swipe :autoplay="3000" indicator-color='#F7B500' class="mt16" @change="onChange">
				<van-swipe-item v-for="(image, index) in list" :key="index">
					<img v-lazy="image.wapPic" class="w260 h330" style="margin: 0 auto;" />
				<!-- 	<div class="w343 h388 flex row-center col-center" style="background: #D8D8D8;margin: 0 auto;">
					</div> -->
				</van-swipe-item>
			</van-swipe>
			<!-- //轮播图 -->
		</div>
	</div>
</template>

<script>
	//引入顶部banner的minix
	import bannerImgJs from '@/mixin/bannerImg.js'
	import { getUpHonors } from '@/api'
	export default {
		name: 'honors',
		data() {
			return {
				list: [],
				bannerType: 4,
				index:0,
				name:''
			};
		},
		mixins: [bannerImgJs],
		components: {},

		created() {
			//获取轮播图
			this.getUpHonors()
		},

		mounted() {},

		methods: {
			//获取轮播图
			async getUpHonors() {
				const res = await getUpHonors()
				this.list=res.data
				this.name=this.list[this.index].productName
			},
			///轮播图切换
			onChange(e) {
				this.name=this.list[e].productName
			}
		}
	}
</script>
<style lang='scss' scoped>
	.team_name {
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translateY(50%);
	}
</style>
